<template>
    <a-layout>
        <a-layout-header style="background: #fff; padding: 0"/>
        <a-layout-content style="margin: 0 16px">
            <a-breadcrumb style="margin: 16px 0">
                <a-breadcrumb-item>首页</a-breadcrumb-item>
                <a-breadcrumb-item>新建作品</a-breadcrumb-item>
            </a-breadcrumb>
            <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }" style="margin-bottom: 10px">
                <!--                {{myName}}-->
                <!--                {{iteminfo}}-->
                <!--                {{imglist220}}-->
                <!--                {{imglist800}}-->
                <h1 style="font-size: larger">基本信息</h1>
                <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 12 }">
                    <a-form-item
                            help="建议尺寸：800*800像素，最多上传5张"
                            label="作品封面图片"

                    >
                        <!-- 点击上传拍照/上传图片 -->


                        <a-upload
                                :action="server_url+'api/admin/tools/saveFileCropper?uid='+uid+'&token='+token+'&save_path=/images&size=268:376'"
                                :file-list="iteminfo.fileList"
                                @change="handleChange"
                                @preview="handlePreview"
                                list-type="picture-card"
                        >
                            <div v-if="iteminfo.fileList.length < 1">
                                <a-icon type="plus"/>
                                <div class="ant-upload-text">
                                    点击上传
                                </div>
                            </div>
                        </a-upload>

                        <a-modal :footer="null" :visible="previewVisible" @cancel="handleCancel">
                            <img :src="previewImage" alt="" style="width: 100%"/>
                        </a-modal>
                        <!--                        <a-input id="image_groups" placeholder="请输入作品名字"/>-->
                    </a-form-item>


                    <a-form-item
                            help="请输入作品名字"
                            label=" 作品标题:"

                    >
                        <a-input id="title" placeholder="请输入作品名字" v-model="iteminfo.name"/>
                    </a-form-item>
                    <a-form-item
                            help="请输入作品副标题"
                            label=" 作品副标题:"

                    >
                        <a-input id="subtitle" placeholder="请输入作品副标题" v-model="iteminfo.subtitle"/>
                    </a-form-item>
                    <a-form-item
                            help="请输入作品视频链接"
                            label=" 作品视频链接:"

                    >
                        <a-input id="playurl" placeholder="请输入作品视频链接" v-model="iteminfo.playurl"/>
                    </a-form-item>

                    <a-form-item
                            help=""
                            label="作品分类:"

                    >
                        <a-cascader :options="Category"
                                    @change="onChangecascader"
                                    allowClear
                                    change-on-select
                                    placeholder="请正确选择作品分类"
                                    style="width: 70%;"
                                    :fieldNames="fieldNames"
                        />
                    </a-form-item>
                    <a-form-item
                            help="将会出现在封面正下方"
                            label="版权信息:"

                    >
                        <a-textarea
                                v-model="copyright"
                                placeholder="请输入作品简介.."
                                :auto-size="{ minRows: 3, maxRows: 15 }"
                                @change="onChange"
                        />
                    </a-form-item>
<!--                    {{FormModel.content_value}}-->
<!--                    {{FormModel.content_value.length}}-->
                    <a-form-model
                            :model="FormModel"
                            ref="FormModel"
                            v-bind="formItemLayoutWithOutLabel"
                    >


                        <!--                {{FormModel.content_value}}-->
                        <!--                {{FormModel.content_value.length}}-->


                        <a-form-model-item
                                :key="index"
                                :label="index === 0 ? '项目' : ''"
                                v-bind="index === 0 ? formItemLayout : {}"
                                v-for="(item, index) in FormModel.content_value"

                        >
                            <a-input
                                    placeholder="请输入左侧标题"
                                    style="width: 20%; margin-right: 8px"
                                    v-model="item.name"
                                    @change="onChangeslab(index,item.name)"
                            />
                            <!--                        <a-select v-model="item.type" disabled="" style="width: 11%; margin-right: 8px">-->
                            <!--                            <a-select-option value="input">-->
                            <!--                                输入框-->
                            <!--                            </a-select-option>-->
                            <!--                            <a-select-option value="number">-->
                            <!--                                数字框-->
                            <!--                            </a-select-option>-->
                            <!--                            <a-select-option value="textarea">-->
                            <!--                                文本域-->
                            <!--                            </a-select-option>-->
                            <!--                        </a-select>-->
                            <a-input

                                    allowClear
                                    style="width: 20%; margin-right: 8px"
                                    v-model="item.value"
                                    placeholder="请输入右侧内容"
                            />

                            <a-icon
                                    :disabled="FormModel.content_value.length === 1"
                                    @click="removeFormModel(item)"
                                    class="dynamic-delete-button"
                                    type="minus-circle-o"
                                    v-if="FormModel.content_value.length > 1"
                            />
                        </a-form-model-item>
                        <a-form-model-item
                                :label="index === 0 ? '项目' : ''"
                        >
                            <small>左侧输入指定文本"上映时间"时: 前台页面和https://www.ghibli.jp/一样强行增加高度</small>
                        </a-form-model-item>
                        <a-form-model-item>
                            <!--                        <a-radio-group button-style="solid"  @change="onChangesFormModel" default-value="input">-->
                            <!--                            <a-radio-button value="input">-->
                            <!--                                输入框-->
                            <!--                            </a-radio-button>-->
                            <!--                            <a-radio-button value="number" disabled="">-->
                            <!--                                数字输入框-->
                            <!--                            </a-radio-button>-->
                            <!--                            <a-radio-button value="textarea" disabled="">-->
                            <!--                                文本域-->
                            <!--                            </a-radio-button>-->
                            <!--                        </a-radio-group>-->
                            <!--                        <br/>-->
                            <!--                        <small>// 表单类型 (text,password,select,date,number,radio,checkbox,switch,textarea,slot) </small>-->
                            <!--                        <br/>-->
                            <a-button @click="addFormModel" style="width: 60%" type="dashed">
                                <a-icon type="plus"/>
                                增加新项目
                            </a-button>
                        </a-form-model-item>
                    </a-form-model>
                </a-form>
            </div>
            <div :style="{ padding: '24px', background: '#fff',}" style="margin-bottom: 10px">


<!--                <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 12 }">-->
<!--                    <h1 style="font-size: larger">作品简介</h1>-->
<!--                    &lt;!&ndash;                    <quill-editor&ndash;&gt;-->
<!--                    &lt;!&ndash;                            v-model="short_content"&ndash;&gt;-->
<!--                    &lt;!&ndash;                            ref="myQuillEditor"&ndash;&gt;-->
<!--                    &lt;!&ndash;                            :options="editorOption"&ndash;&gt;-->
<!--                    &lt;!&ndash;                            @change="onEditorChange1($event)">&ndash;&gt;-->
<!--                    &lt;!&ndash;                    >&ndash;&gt;-->
<!--                    &lt;!&ndash;                    </quill-editor>&ndash;&gt;-->
<!--                    <a-textarea-->
<!--                            v-model="short_content"-->
<!--                            placeholder="请输入作品简介.."-->
<!--                            :auto-size="{ minRows: 3, maxRows: 15 }"-->
<!--                            @change="onChange"-->
<!--                    />-->
<!--                </a-form>-->
                <h1 style="font-size: larger">作品详情</h1>
                <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 20}">
                    <div class="edit_container">
                        <a-upload list-type="picture" v-show="false"
                                  :action="server_url+'api/admin/tools/saveFile?uid='+uid+'&token='+token+'&save_path=/images/item'"
                                  :show-upload-list="false"
                                  :before-upload="beforeUploaditem"
                                  @change="handleChangeitem"
                        >
                            <a-button> <a-icon type="upload" /> upload </a-button>
                        </a-upload>
                        <quill-editor
                                v-model="content"
                                ref="myQuillEditor"
                                :options="editorOption"
                                @blur="onEditorBlur($event)"
                                @focus="onEditorFocus($event)"
                                @ready="onEditorReady($event)"
                                @change="onEditorChange($event)">
                        </quill-editor>
                    </div>
                </a-form>
                <a-form-item
                        help="建议尺寸：800*800像素，最多上传80张"
                        label="作品图片集"

                >
                    <!-- 点击上传拍照/上传图片 -->
                        {{imggroup}}
                    {{iteminfo.length}}

                    <a-upload
                            :action="server_url+'api/admin/tools/saveFile?uid='+uid+'&token='+token+'&save_path=/images/production'"
                            :file-list="iteminfo.fileList_collections"
                            @change="handleChange_collections"
                            @preview="handlePreview"
                            list-type="picture-card"
                            :multiple="true"
                    >
                        <div v-if="iteminfo.fileList_collections.length < 80">
                            <a-icon type="plus"/>
                            <div class="ant-upload-text">
                                点击上传
                            </div>
                        </div>
                    </a-upload>

                    <a-modal :footer="null" :visible="previewVisible_collections" @cancel="handleCancel">
                        <img :src="previewImage_collections" alt="" style="width: 100%"/>
                    </a-modal>
                    <!--                        <a-input id="image_groups" placeholder="请输入作品名字"/>-->
                </a-form-item>
            </div>
            <a-button @click="addproduction()" type="primary">添加作品</a-button>
            <!--            <a-button @click="addsku()" type="primary" v-show="sku_array=='multiple'">添加多个作品</a-button>-->
        </a-layout-content>
        <a-layout-footer style="text-align: center"></a-layout-footer>

        <!--        <a-modal title="" v-model="" @ok="" width="45%">-->

        <!--        </a-modal>-->
    </a-layout>

</template>

<script>


    // import {Modal} from "ant-design-vue";
    import Storage from "../common/storage";
    // import moment from "moment";
    import Config from '../config'
    import {Modal} from "ant-design-vue";

    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    import { quillEditor } from 'vue-quill-editor'
    // import * as Util from "../common/util";
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const columns = [
        // {
        //     title: "attributes",
        //     dataIndex: "attributes",
        //     scopedSlots: {customRender: "attributes"}
        // },
        {
            title: "标题",
            dataIndex: "name",
            scopedSlots: {customRender: "name"}
        },
        {
            title: "库存",
            dataIndex: "inventory",
            scopedSlots: {customRender: "inventory"}
        },
        {
            title: "低于该库存警告",
            dataIndex: "inventory_threshold",
            scopedSlots: {customRender: "inventory_threshold"}
        },
        // {
        //     title: "运费模板",
        //     dataIndex: "freight_model_id",
        //     scopedSlots: {customRender: "freight_model_id"}
        // },
        {
            title: "价格",
            dataIndex: "price",
            scopedSlots: {customRender: "price"}
        },
        {
            title: "成本",
            dataIndex: "cost",
            scopedSlots: {customRender: "cost"}
        },
        {
            title: "重量",
            dataIndex: "weight",
            scopedSlots: {customRender: "weight"}
        },
        {
            title: "状态",
            dataIndex: "is_sale",
            scopedSlots: {customRender: "is_sale"}
        },
        {
            title: "操作",
            dataIndex: "operation",
            scopedSlots: {customRender: "operation"}
        },
    ];
    // 工具栏配置
    const toolbarOptions = [
        ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
        // ["blockquote", "code-block"], //引用，代码块
        [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
        //[{ list: "ordered" }, { list: "bullet" }], //列表
        //[{ script: "sub" }, { script: "super" }], // 上下标
        [{ indent: "-1" }, { indent: "+1" }], // 缩进
        [{ direction: "rtl" }], // 文本方向
        [{ size: ["small", false, "large", "huge"] }], // 字体大小
        [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
        [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
        [{ font: [] }], //字体
        [{ align: [] }], //对齐方式
        ["clean"], //清除字体样式
        ["image"], //上传图片、上传视频
        // ["image", "video"], //上传图片、上传视频
    ];
    export default {
        components: {quillEditor},
        name: 'editor',
        data() {
            return {
                uploadImgUrl: '',
                short_content: '',
                copyright: '',
                imageUrl: '',
                content: ``,  // 富文本编辑器默认内容
                editorOption: {
                    modules: {
                        toolbar: {
                            container: this.toolbarOptions || toolbarOptions, // 自定义工具栏，与上面定义的toolbarOptions 相对应
                            handlers: {
                                'image': function(value) {
                                    if (value) {
                                        console.log('你点到我了!')
                                        // 获取到antd的upload框选择图片的弹出框事件并触发一次点击
                                        document.querySelector('.ant-upload .ant-btn').click()
                                    } else {
                                        //禁用quill自带的image时间
                                        this.quill.format('image', false)
                                    }
                                }
                            } // 事件重写
                        }
                    },
                    theme: "snow",  //主题
                    placeholder: "请输入正文",
                },
                columns,
                data: [],
                loading: false,
                add: {},
                Attributedata: [],
                Category: [],
                Attributedataarr: [],
                previewVisible: false,
                previewVisible_collections: false,
                previewImage: '',
                previewImage_collections: '',
                server_url: Config.data.server_url,
                Categorydata: Storage.Categorydata,
                uid: Storage.uid,
                token: Storage.token,
                myName: Storage.myName,
                fieldNames: {
                    children: 'child',
                    label: 'title',
                    value: 'title',
                },
                imglist800: "",
                imglist220: [],
                imggroup: [],
                article_category_id: "",
                article_category_selectedOptions: "",
                // sku_array:"multiple",
                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 3},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 20},
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: {span: 24, offset: 0},
                        sm: {span: 20, offset: 3},
                    },
                },
                FormModel: {
                    content_value: [],

                },
                FormModeltemp:{
                    type:"input",
                    key_code:"",
                    set_init_value:"",
                },

                iteminfo: {
                    name: "",
                    is_sale: "2",
                    price: 0,
                    cost: 0,
                    freight_model_id: 0,
                    weight: 0,
                    inventory: 0,
                    shop_id: 1,
                    inventory_threshold: 100,
                    category: "",
                    attributes: [],
                    sku_array: [],
                    fileList: [],
                    fileList_collections: [],
                    content: {
                        categorygroup:"",
                        content:"",
                    },
                }
            };
        },
        created() {
            // 获取第一页内容
            // this.doSearch(1, this.pagination.pageSize, "");
            this.getCategory();
        },
        computed: {
            editor() {
                return this.$refs.myQuillEditor.quill;
            },
        },
        methods: {
            async getCategory(pid) {
                this.loading = true;
                this.Category = [];
                console.log(Storage.uid)
                console.log(Storage.token)
                let now = Date.now();
                let result = await this.$get("/api/admin/articleCategory/list", {
                    params: {
                        token: Storage.token,
                        uid: Storage.uid,
                        pid: pid,
                        t: now,
                    }
                });

                if (result.status === true) {

                    this.Category = result.data;
                    this.loading = false;
                }

                this.loading = false;
            },
            // 准备编辑器
            onEditorReady(editor) {
                console.log('editor ready!', editor)
            },
            // 失去焦点事件
            onEditorBlur(editor){
                console.log('editor blur!', editor)
            },
            // 获得焦点事件
            onEditorFocus(editor){
                console.log('editor focus!', editor)
            },
            // 内容改变事件
            onEditorChange({ quill, html, text }){
                console.log('editor change!', quill, html, text)
                this.content = html
            },
            start() {
                this.loading = true;
                this.data = [];
                // ajax request after empty completing
                setTimeout(() => {
                    this.loading = false;
                    this.selectedRowKeys = [];
                }, 1000);
                this.doSearch(1, this.pagination.pageSize);
            },
            handleCancel() {
                this.previewVisible = false;
                this.previewVisible_collections = false;
            },
            onChangecascader(value, selectedOptions) {
                // this.skugroups=[];
                // this.Attributedataarr=[];
                // for (let i in selectedOptions) {
                //     let info = selectedOptions[i].id;
                //     console.log(info);
                //     // this.iteminfo.category = info
                //
                //     // this.add.p_lv = value
                // }
                // this.iteminfo.content.categorygroup = value
                // this.getAttribute(this.iteminfo.category);
                this.article_category_id = selectedOptions[selectedOptions.length-1].id;
                this.article_category_selectedOptions = value;
                // console.log(value);
                // console.log(selectedOptions);
                console.log(this.article_category_id );
            },
            async handlePreview(file) {

                if (!file.url && !file.preview) {
                    file.preview = await getBase64(file.originFileObj);
                }
                this.previewImage = file.url || file.preview;
                this.previewVisible = true;
            },
            handleChange(info) {
                this.imglist800="";
                // this.imglist220=[];
                // console.log(info);
                console.log(info.file.status);
                this.iteminfo.fileList = info.fileList;
                for (var i = 0; i < this.iteminfo.fileList.length; i++) {
                    if(info.file.status == "done" || info.file.status == "removed"){
                        this.imglist800 = this.iteminfo.fileList[i].response.data[0];
                        // this.imglist800.push(this.iteminfo.fileList[i].response.data[0]);
                        // this.imglist220.push(this.iteminfo.fileList[i].response.data[1]);
                    }
                    // console.log(info.fileList[i]);
                    // console.log(info.fileList[i].response.data);
                }


            },
            handleChange_collections(info) {
                // this.imglist800="";
                this.imggroup=[];
                // console.log(info);
                console.log(info.file.status);
                this.iteminfo.fileList_collections = info.fileList;
                for (var i = 0; i < this.iteminfo.fileList_collections.length; i++) {

                    if(info.file.status == "done" || info.file.status == "removed"){
                        // console.log(i,this.iteminfo.fileList_collections[i].response.data.src);
                        // this.imggroup = this.iteminfo.fileList_collections[i].response.data[0];
                        this.imggroup.push(this.iteminfo.fileList_collections[i].response.data.src);
                        // this.imglist220.push(this.iteminfo.fileList[i].response.data[1]);
                    }
                    // console.log(info.fileList[i]);
                }

                console.log(this.iteminfo.fileList_collections);

            },

            onChange() {
                let content = this.short_content.replace(/<[^>]*>/g,'');

                if (content.length > 200) {
                    content = content.substring(0, 200) + '...';

                }
                this.short_content = content
            },






            async addproduction() {

                //
                if (this.iteminfo.name =="") {
                    Modal.warning({
                        title: "请输入作品标题"
                    });
                    return
                }
                if (this.content =="" || this.content =="作品详情") {
                    Modal.warning({
                        title: "请输入作品正文"
                    });
                    return
                }
                if (this.article_category_id == 1) {
                    Modal.warning({
                        title: "请不要选择根作为作品分类"
                    });
                    return
                }
                if (this.article_category_id =="") {
                    Modal.warning({
                        title: "请选择作品分类"
                    });
                    return
                }
                // if (this.add.password =="") {
                //     Modal.warning({
                //         title: "请输入登录密码"
                //     });
                //     this.popinfos = true;
                //     return
                // }
                // if (this.add.password != this.add.re_password) {
                //     Modal.warning({
                //         title: "两次密码不一致"
                //     });
                //     this.popinfos = true;
                //     return
                // }
                if(this.short_content == ""){
                    let content = this.content.replace(/<[^>]*>/g,'');

                    if (content.length > 10) {
                        content = content.substring(0, 200) + '...';

                    }
                    this.short_content = content
                }


                let now = Date.now();
                let result = await this.$post("/api/admin/article/create", {
                    uid: Storage.uid,
                    token: Storage.token,
                    save_value: {

                        title: this.iteminfo.name,
                        short_content : this.short_content,

                        article_category_id: this.article_category_id,
                        // cover_images : JSON.stringify(this.imglist800),
                        cover_images : this.imglist800,
                        author: this.myName,
                        type:2,
                        model_info_json:JSON.stringify({
                            article_category_selectedOptions:this.article_category_selectedOptions,
                            playurl:this.iteminfo.playurl,
                            subtitle:this.iteminfo.subtitle,
                            FormModel:this.FormModel,
                            imggroup:this.imggroup,
                            copyright : this.copyright,
                        }),
                        content:  this.content,
                        t: now,
                    },
                });

                if (result.status === true) {
                    this.data = []
                    // await this.doSearch(1, this.pagination.pageSize, "");
                    Modal.info({
                        title: "创建成功1"
                    });
                    window.vue.$router.push({path: "/productionmanger"});
                }
                // this.popadd = false;
            },

            handleChangeitem(info) {
                console.log('info', info);
                //这一步是获取quilleditor的实例
                let quill = this.$refs.myQuillEditor.quill

                // 获取光标所在位置
                let length = quill.getSelection().index
                if (info.file.status === 'uploading') {
                    this.loading = true;
                    return;
                }
                if (info.file.status === 'done') {
                    // Get this url from response in real world.
                    this.loading = false;
                    this.uploadImgUrl = this.server_url+info.file.response.data.src
                    console.log(length)
                    // 插入图片  res为服务器返回的图片地址
                    quill.insertEmbed(length, 'image', this.uploadImgUrl)
                    // 调整光标到最后
                    quill.setSelection(length + 1)
                    this.$message.success('上传成功')
                    //console.log(this.url)
                    // this.$message.error('图片插入失败')
                }
            },
            beforeUploaditem(file) {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    this.$message.error('You can only upload JPG file!');
                }
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    this.$message.error('Image must smaller than 2MB!');
                }
                return isJpgOrPng && isLt2M;
            },
            submitForm(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        alert('submit!');
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            removeFormModel(item) {
                let index = this.FormModel.content_value.indexOf(item);
                if (index !== -1) {
                    this.FormModel.content_value.splice(index, 1);
                }
            },
            addFormModel() {
                this.FormModel.content_value.push({
                    name: '',
                    value: '',
                });
            },
            async onChangeslab(index,label) {
                console.log(index)
                console.log(label)
                // this.FormModel.content_value[index].value = label;
            },
        }
    };
</script>
<style>
    /* you can make up upload button and sample style by using stylesheets */
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }
    .edit_container{min-height: 400px}
    .quill-editor{min-height: 200px}
    .ql-editor{
        height:400px;
    }
    .ql-snow .ql-tooltip[data-mode="link"]::before {
        content: "请输入链接地址:";
    }
    .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
        border-right: 0px;
        content: "保存";
        padding-right: 0px;
    }
    .ql-snow .ql-tooltip[data-mode="video"]::before {
        content: "请输入视频地址:";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item::before {
        content: "14px";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
        content: "10px";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
        content: "18px";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
        content: "32px";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item::before {
        content: "文本";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
        content: "标题1";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
        content: "标题2";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
        content: "标题3";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
        content: "标题4";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
        content: "标题5";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
        content: "标题6";
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item::before {
        content: "标准字体";
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
        content: "衬线字体";
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
        content: "等宽字体";
    }
</style>
